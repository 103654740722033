import { Row, Col, Tab, Tabs } from "react-bootstrap";
import MediaPlayer from "../components/MediaPlayer";
import Cameras from "../components/Cameras";
import VideoAnalysis from "../components/MediaAnalysis";
import { useContext, useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import PageLayout from "../components/PageLayout";
import { getGroups } from "../services/Groups";
import { GroupItem } from "../models/Group";
import { CameraItem } from "../models/Camera";
import { SignInContext } from "../context/SignInContext";
import Spinner from "../components/Spinner";

import Alert from "react-bootstrap/Alert";
import OffCanvas from "../components/OffCanvas";
import { NotificationItem } from "../models/Notification";
import { getNotifications } from "../services/Notification";
import { useNotificationItemsContext } from "../context/NotificationContext";

const GroupTabContent: React.FC<{
  groupId?: number;
  isSingleGroup: boolean;
}> = ({ groupId, isSingleGroup }) => {
  const [cameraItems, setCameraItems] = useState<CameraItem[]>([]);

  const handleCamerasLoaded = (loadedCameras: CameraItem[]) => {
    setCameraItems(loadedCameras);
  };

  return (
    <Row className="mt-3">
      <Col md={3} xl={3} l={4} sm={12} xs={12} className="mt-3 px-1">
        <Cameras groupId={groupId} onCamerasLoaded={handleCamerasLoaded} />
      </Col>
      {cameraItems !== null && cameraItems.length > 0 ? (
        <>
          <Col md={6} xl={6} l={4} sm={12} xs={12} className="mt-3 px-1">
            <MediaPlayer />
          </Col>
          <Col md={3} xl={3} l={4} sm={12} xs={12} className="mt-3 px-1">
            <VideoAnalysis />
          </Col>
        </>
      ) : (
        <Col
          md={12}
          xl={12}
          l={12}
          sm={12}
          xs={12}
          className="d-flex justify-content-center align-items-center mt-3 px-1"
        >
          <div>
            {isSingleGroup
              ? "No cameras available."
              : "No cameras available. Please try selecting another group."}
          </div>
        </Col>
      )}
    </Row>
  );
};
// Define options for left offcanvas component for active notifications
const activeOptions = [
  {
    scroll: true,
    backdrop: true,
    placement: ["start"],
  },
];
// Define options for right offcanvas component for past notifications
const pastOptions = [
  {
    scroll: true,
    backdrop: true,
    placement: ["end"],
  },
];
const Dashboard = () => {
  const auth = useAuth();
  const [groups, setGroups] = useState<GroupItem[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<number | undefined>(
    undefined
  );
  const isSingleGroup = groups && groups.length === 1;
  const { userGroupsCount } = useContext(SignInContext);
  const { showAlert, setShowAlert } = useNotificationItemsContext();
  const [notificationItems, setNotificationItems] = useState<
    NotificationItem[]
  >([]);

  // function to return number of notifications, either active or past.
  const notificationCount = (type: boolean) => {
    const notificationCounts = notificationItems.filter(
      (notification) => notification.is_read === type
    );
    return notificationCounts.length;
  };
  const loadNotifications = () => {
    const getNotifications$ = getNotifications({
      is_read: false,
    });
    const sub = getNotifications$.subscribe({
      next: (res: any) => {
        console.log(res.results);
        setNotificationItems(res.results);
      },
    });
    return () => sub.unsubscribe();
  };

  const loadGroups = () => {
    getGroups({ userGroups: true }).then((items: any) => {
      let filtered_items = items.filter(
        (item: GroupItem) =>
          item?.groupprop?.is_visible === true || !item?.groupprop
      );
      setGroups(filtered_items);
      console.log("all groups", items);
      if (filtered_items.length) setSelectedGroup(filtered_items[0].id);
    });
  };
  useEffect(() => {
    userGroupsCount && loadGroups();
  }, [userGroupsCount]);

  useEffect(() => {
    setShowAlert(false); // Intially set the display to false
    loadNotifications();
  }, []);

  useEffect(() => {
    // Hide the alert panel if there is no active notifications
    if (notificationCount(false) > 0) setShowAlert(true);
    else setShowAlert(false);
  }, [notificationItems]);

  const handleTabSelect = (groupId: number | null) => {
    // Set the selected group when a tab is clicked
    setSelectedGroup(groupId !== null ? groupId : undefined);
  };

  return (
    <PageLayout title="Dashboard">
      {userGroupsCount == null && <Spinner />}

      {auth.isAuthenticated && userGroupsCount === 0 && (
        <p>
          {" "}
          You’re logged in but don’t currently have access to any cameras.
          Contact
          <b>
            <a href="mailto:" className="text-black">
              {" "}
              test@example.edu.au
            </a>
          </b>{" "}
          to become a community member.
        </p>
      )}
      {auth.isAuthenticated &&
        userGroupsCount != null &&
        userGroupsCount > 0 && (
          <Row className="mt-3">
            {showAlert ? (
              <Alert show={true} variant="danger">
                <Alert.Heading>Alerts</Alert.Heading>
                <p>
                  There are some unread notifications! Click on the 'View Active
                  Alerts" button to see them all.
                </p>
                <hr />
                <div className="d-flex justify-content-end">
                  {pastOptions.map((props, idx) => (
                    <OffCanvas title="Past Notifications" {...props} />
                  ))}
                  {activeOptions.map((props, idx) => (
                    <OffCanvas title="Active Notifications" {...props} />
                  ))}
                </div>
              </Alert>
            ) : (
              <span></span>
            )}

            {isSingleGroup ? (
              <GroupTabContent isSingleGroup={isSingleGroup} />
            ) : (
              <Tabs
                activeKey={selectedGroup}
                onSelect={(groupId) => handleTabSelect(Number(groupId))}
                className="mb-3"
              >
                {groups?.map((group) => (
                  <Tab key={group.id} eventKey={group.id} title={group.name}>
                    {selectedGroup === group.id && (
                      <GroupTabContent
                        groupId={group.id}
                        isSingleGroup={isSingleGroup}
                      />
                    )}
                  </Tab>
                ))}
              </Tabs>
            )}
          </Row>
        )}
    </PageLayout>
  );
};

export default Dashboard;
