import { Container } from 'react-bootstrap';
import TopNavbar from './TopNavbar';
import SelectedVideoProvider from '../context/MediaContext';
import { ReactNode } from 'react';
import SelectedLocationProvider from '../context/RegionContext';
import SelectedIdentifiedObjectProvider from '../context/IdentifiedObjectContext';
import { UserLocationProvider } from '../context/UserLocationContext';


interface PageLayoutProps {
    children: ReactNode;
    title?: string;
}


const PageLayout = ({ children, title }: PageLayoutProps) => {
    return (
        <div>
            <TopNavbar />
            <Container>
                <UserLocationProvider>
                    <SelectedLocationProvider>
                        <SelectedVideoProvider>
                            <SelectedIdentifiedObjectProvider>
                                <main className="content">
                                    <div className="row mt-5 mt-lg-5">
                                        {title && <h4>{title}</h4>}
                                    </div>
                                    {children}
                                </main>
                            </SelectedIdentifiedObjectProvider>
                        </SelectedVideoProvider>
                    </SelectedLocationProvider>
                </UserLocationProvider>
            </Container>
        </div>
    );
};

export default PageLayout;
