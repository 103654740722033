import { Navigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { ReactNode, useContext } from "react";
import { SignInContext } from "../context/SignInContext";

interface AdminRouteProps {
  children: ReactNode;
}

const AdminRoute = ({ children }: AdminRouteProps) => {
  const auth = useAuth();
  const { isAdminUser } = useContext(SignInContext);

  if (auth.isLoading) {
    return null;
  }

  if (auth.isAuthenticated && isAdminUser) {
    return <>{children}</>;
  }
  return <Navigate to={"/"} replace />;
}

export default AdminRoute;
