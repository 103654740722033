import { Observable } from "rxjs";
import { customAjax } from "../components/ErrorHandler";
import { baseURL } from "./ServiceUtil";
import { EditNotificationItem } from "../models/Notification";

export const getNotifications = (options: {
  page?: number | "all";
  is_read: boolean;
}): Observable<any> => {
  let queryParams = "";

  if (options.page !== undefined && options.page !== null) {
    if (options.page === "all") {
      queryParams = new URLSearchParams({ page_size: "all" }).toString();
    } else {
      queryParams = new URLSearchParams({
        page: options.page.toString(),
      }).toString();
    }
  }
  // Add logic to include is_read parameter
  queryParams += `${queryParams ? "&" : ""}is_read=${options.is_read}`;

  const url = `${baseURL}/notifications${queryParams ? "?" + queryParams : ""}`;

  return customAjax(url);
};

export const editNotification = (
  notificationId: string,
  notificationPayload: EditNotificationItem
) =>
  customAjax(
    `${baseURL}/notifications/${notificationId}`,
    "PUT",
    notificationPayload
  );

export const readAllNotification = () =>
  customAjax(`${baseURL}/notifications/acknowledge_all`, "POST");
