import { Observable } from "rxjs";
import { baseURL } from "./ServiceUtil";
import {
  IdentifiedSpeciesCount,
  IdentifiedObjectItem,
  PostMediaAnalysisItem,
} from "../models/Media";
import { customAjax } from "../components/ErrorHandler";
import fetchData from "../utils/getAllPaginated";

export const getMedias = (
  ids: number[],
  startDate?: string,
  endDate?: string,
  groupId?: number | null,
  specieIds?: IdentifiedObjectItem[] | null,
  page?: number | null
): Observable<any> => {
  let url = `${baseURL}/media?camera__id__in=${ids}`;

  if (startDate) {
    const formattedStartDate = new Date(startDate);
    formattedStartDate.setHours(0, 0, 0, 0); // Set to start of localised start date
    const isoFormattedStartDate = formattedStartDate.toISOString();
    url += `&date__gte=${isoFormattedStartDate}`;
  }

  if (endDate) {
    const formattedEndDate = new Date(endDate);
    formattedEndDate.setHours(23, 59, 59, 999); // Set to end of localised enddate
    const isoFormattedEndDate = formattedEndDate.toISOString();
    url += `&date__lte=${isoFormattedEndDate}`;
  }

  if (groupId) {
    url += `&group=${groupId}`;
  }

  let tempString = "";
  if (specieIds) {
    for (let i = 0; i < specieIds.length; i++) {
      console.log(`specieIds with ${i}`, specieIds[i]);
      tempString += `&species=${specieIds[i].id}`;
    }

    url += tempString;
  }

  if (page) {
    let pagestring = `&page=${page}`;
    url += pagestring;
  }
  return customAjax(url);
};

export const getMediaById = (id: number): Observable<any> => {
  return customAjax(`${baseURL}/media/${id}`);
};

export const postMediaAnalysis = (
  mediaAnalysisPayload: PostMediaAnalysisItem
) => customAjax(`${baseURL}/community_feedback`, "POST", mediaAnalysisPayload);

export const updateMediaAnalysis = (
  mediaAnalysisPayload: PostMediaAnalysisItem
) =>
  customAjax(
    `${baseURL}/community_feedback/${mediaAnalysisPayload.id}`,
    "PUT",
    mediaAnalysisPayload
  );

// export const getIdentifiedObjects = (): fetchData<IdentifiedObjectItem[]> => {
//   return customAjax(`${baseURL}/species`);
// };
export const getIdentifiedObjects = (): Promise<IdentifiedObjectItem> => {
  return fetchData(`${baseURL}/species`);
};

export const getIdentifiedObjectsCountByFilters = (options: {
  group_ids: number[] | undefined;
  region_ids: number[] | undefined;
  species_ids: number[] | undefined;
}): Promise<IdentifiedSpeciesCount[]> => {
  let url = `${baseURL}/identified_species_counts`;
  let filter = ``;
  // Check if 'group_ids' is defined before adding it to the URL
  if (options?.group_ids) {
    filter += `group_ids=${options.group_ids}`;
  }

  if (options?.region_ids) {
    filter += `&region_ids=${options.region_ids}`;
  }

  if (options?.species_ids) {
    filter += `&species_ids=${options.species_ids}`;
  }
  // console.log("print the url to fecth", url);
  return fetchData(url, filter);
};
export const getIdentifiedObjectsCounts = (
  ids: number[] | undefined
): Observable<IdentifiedSpeciesCount[]> => {
  // Check if 'ids' is defined before adding it to the URL
  const idQueryParam = ids ? `location__id__in=${ids}` : "";
  const url = `${baseURL}/identified_species_counts${
    idQueryParam ? `?${idQueryParam}` : ""
  }`;
  return customAjax(url);
};
