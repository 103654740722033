import { Tab, Tabs } from "react-bootstrap";
import PageLayout from "../components/PageLayout";
import GroupEntries from "../components/GroupEntries";
import UserEntries from "../components/UserEntries";

const ManageGroups = () => {

    return (
        <PageLayout title="Manage Community Groups">
            <Tabs
                defaultActiveKey="users"
                className="my-3"
                fill
            >
                <Tab eventKey="users" title="Users">
                    <UserEntries />
                </Tab>
                <Tab eventKey="groups" title="Groups">
                    <GroupEntries />
                </Tab>
            </Tabs>
        </PageLayout>
    )
}

export default ManageGroups;