export interface MediaItem {
  id: number;
  url: string;
  camera: number;
  title: string;
  specie: number[];
  media_file: string;
  date: string;
  latest_ai_model_feedback?: MediaAnalysisItem[];
  all_ai_model_feedback?: MediaAnalysisItem[];
  ai_model: AIModelInfoItem;
  media_type: MediaType;
  latest_ai_model_identified_species_counts: IdentifiedSpeciesCount[];
  all_ai_model_identified_species_counts: IdentifiedSpeciesCount[];
}

export enum MediaType {
  Image = "image",
  Video = "video",
}

export interface IdentifiedObjectItem {
  id: number;
  name: string;
}

export interface IdentifiedSpeciesCount {
  url: string;
  identified_object_name: string;
  count: number;
  ai_model: string;
  ai_model_name: string;
  ai_model_version: string;
  media: MediaItem;
  date: string;
  region_name: string;
}

export interface MediaAnalysisItem {
  url: string;
  comment: string;
  has_ai_correctly_detected: boolean;
  created_by: string;
  created_at: string;
  id: number;
  ai_model: string;
}

export interface PostMediaAnalysisItem {
  comment: string;
  media: number;
  has_ai_correctly_detected: boolean;
  id?: number;
  ai_model: string;
}

export interface AIModelInfoItem {
  name: string;
  version: string;
  url: string;
}
