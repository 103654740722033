import { Col, ListGroup, Row } from "react-bootstrap";
import { User } from "../models/User";
import { useEffect, useState } from "react";
import AddUser from "./AddUser";
import CustomModal from "./CustomModal";
import { deleteUser, getUsers } from "../services/Users";
import Paginator from "./Paginator";
import { toast } from "react-toastify";
import Spinner from "./Spinner";

const UserEntries = () => {
    const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
    const [users, setUsers] = useState<User[]>([])
    const [formData, setFormData] = useState<any>(null);
    const [page, setPage] = useState<any>(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [loading, setLoading] = useState(true);


    const loadUsers = () => {
        setLoading(true)
        const getUsers$ = getUsers(pageNumber);
        const sub = getUsers$.subscribe({
            next: (res: any) => {
                setPage(res);
                setUsers(res.results)
                setLoading(false)
            },
        });
        return () => sub.unsubscribe();
    };

    useEffect(() => {
        loadUsers();
    }, [pageNumber]);

    const onUserDelete = () => {
        const toastId = toast.loading("Please wait...");
        const deleteUser$ = deleteUser(formData.id);
        const sub = deleteUser$.subscribe({
            next: (res: any) => {
                loadUsers()
                setShowDeleteModal(false)
                toast.update(toastId, {
                    render: "User has been deleted successfully.",
                    type: "success",
                    isLoading: false,
                    autoClose: 3000,
                    closeButton: true,
                    hideProgressBar: false,
                    theme: "colored",
                });
            },
            error: (e) => {
                setShowDeleteModal(false)
                toast.update(toastId, {
                    render: "Error! Something went wrong",
                    type: "error",
                    isLoading: false,
                    autoClose: 3000,
                    closeButton: true,
                    hideProgressBar: false,
                });
                console.error('Error', e)
            }
        });
        return () => sub.unsubscribe();
    }

    return (
        <>
            {loading ? <Spinner /> :
                <>
                    <CustomModal
                        show={showAddUserModal}
                        scrollable={false}
                        modalBody={
                            <AddUser
                                onClose={() => {
                                    setShowAddUserModal(false);
                                    loadUsers()
                                }}
                            />
                        }
                    />
                    <CustomModal
                        cancelButtonText={"Cancel"}
                        confirmButtonText={"Delete"}
                        modalTitle={"Delete user"}
                        show={showDeleteModal}
                        scrollable={true}
                        modalBody={
                            <div>
                                Are you sure you want to delete <b>{formData?.name}({formData?.email})</b> user account?
                            </div>
                        }
                        onModalClose={() => {
                            setFormData(null);
                            setShowDeleteModal(false);
                        }}
                        onConfirmed={() => onUserDelete()}
                    />
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={12} className="d-flex justify-content-end">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => setShowAddUserModal(true)}
                            >
                                + Add User
                            </button>
                        </Col>
                    </Row>

                    <Row className="mt-2">
                        <ListGroup>
                            {users.map((user) => (
                                <ListGroup.Item
                                    className="d-flex justify-content-between align-items-start"
                                    key={user.id}
                                >
                                    <div className="ms-2 me-auto p-0">
                                        <div className="">
                                            <span className="fw-bold">{`${user.first_name} ${user.last_name} ${user.username}`}</span>
                                            {user.groups && user.groups.length > 0
                                                ? <div><small>Member of {user.groups.map(group => group).join(', ')} group(s)</small></div>
                                                : <div><small>No group assigned yet</small></div>
                                            }

                                        </div>
                                    </div>
                                    <div className="list-item-wrapper">
                                        <button
                                            type="button"
                                            className="btn list-action-button"
                                            onClick={() => {
                                                setFormData(user);
                                                setShowDeleteModal(true);
                                            }}
                                        >
                                            <i className="bi bi-trash3" id={`delete-user-entry-${user.id}`}></i>
                                        </button>
                                    </div>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Row>
                    <Paginator page={page} pageNumber={pageNumber} setPageNumber={setPageNumber} />
                </>
            }

        </>
    )
}

export default UserEntries