import { Observable } from "rxjs";
import { baseURL } from "./ServiceUtil";
import { PostCameraItem } from "../models/Camera";
import { customAjax } from "../components/ErrorHandler";

export const getCameras = (options?: { page?: number | 'all'; group?: number }): Observable<any> => {
  let queryParams = '';

  if (options?.page !== undefined && options.page !== null) {
    if (options.page === 'all') {
      queryParams = new URLSearchParams({ page_size: 'all' }).toString();
    } else {
      queryParams = new URLSearchParams({ page: options.page.toString() }).toString();
    }
  }

  if (options?.group) {
    queryParams += `${queryParams ? '&' : ''}group=${options.group.toString()}`;
  }

  const url = `${baseURL}/cameras${queryParams ? `?${queryParams}` : ''}`;

  return customAjax(url);
};

export const updateCamera = (cameraPayload: PostCameraItem) =>
  customAjax(`${baseURL}/cameras/${cameraPayload.id}`, 'PUT', cameraPayload);


export const getCameraById = (id?: number): Observable<any> => {
    // Check if 'id' is defined before adding it to the URL
    const idQueryParam = id ? `id=${id}` : '';
  
    return customAjax(`${baseURL}/cameras${idQueryParam ? `?${idQueryParam}` : ''}`);
  };


export const postCamera = (cameraPayload: PostCameraItem) =>
  customAjax(`${baseURL}/cameras`, 'POST', cameraPayload);