export const detailsListCustomStyles = {
  backgroundColor: '#f5f8fb',
  contentWrapper: {
    backgroundColor: '#f5f8fb'
  },
  root: {
    selectors: {
      '.ms-DetailsRow': {
        backgroundColor: '#f5f8fb'
      }
    },

  },
  headerWrapper: {
    padding: 0,
    selectors: {
      '.ms-DetailsHeader': {
        backgroundColor: '#f5f8fb',
        padding: 0,
      },
      '.ms-DetailsHeader-cellName': {
        fontSize: '15px',
      },
    },
  },
};

export const datePickerCustomStyle = {
  textField: {
    selectors: {
      '.ms-TextField-fieldGroup': {
        border: '1px solid lightgrey'
      },
    },
  }
}
