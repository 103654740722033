import { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { EditNotificationItem, NotificationItem } from "../models/Notification";
import {
  editNotification,
  getNotifications,
  readAllNotification,
} from "../services/Notification";
import Moment from "react-moment";
import Paginator from "./Paginator";
import { useNotificationItemsContext } from "../context/NotificationContext";

function Notifications({ title }: any) {
  const [readStatus, setReadStatus] = useState(true);
  const [page, setPage] = useState<any>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [notificationItems, setNotificationItems] = useState<
    NotificationItem[]
  >([]);
  const { setShowAlert } = useNotificationItemsContext();

  // function to return number of notifications, either active or past.
  const notificationCount = (type: boolean) => {
    const items = notificationItems.filter(
      (notification) => notification.is_read === type
    );
    return items.length;
  };

  const loadNotifications = () => {
    const getNotifications$ = getNotifications({
      is_read: title === "Active Notifications" ? false : true,
      page: pageNumber,
    });
    const sub = getNotifications$.subscribe({
      next: (res: any) => {
        setNotificationItems(res.results);
        setPage(res);
        if (res.results.length >= 1) {
          setShowAlert(true);
          // console.log("here we are", showAlert);
        } else setShowAlert(false);
      },
    });
    return () => sub.unsubscribe();
  };

  useEffect(() => {
    if (title === "Active Notifications") setReadStatus(false);
    else setReadStatus(true);
    loadNotifications();
  }, [pageNumber]);

  const handleClick = (e: any, severity: string) => {
    const payload: EditNotificationItem = {
      is_read: true,
      message: e.currentTarget.value,
      severity: severity,
    };
    const postNotifiction$ = editNotification(e.currentTarget.id, payload);
    const sub = postNotifiction$.subscribe({
      next: (res: any) => {
        loadNotifications();
        console.log(res);
      },
    });
    if (notificationCount(false) == 0) setShowAlert(false);
    return () => sub.unsubscribe();
  };

  const handleAllClick = () => {
    const readNotifiction$ = readAllNotification();
    const sub = readNotifiction$.subscribe({
      next: (res: any) => {
        if (res == "ok") setShowAlert(false);
      },
    });
    return () => sub.unsubscribe();
  };

  return (
    <>
      {title === "Active Notifications" && notificationCount(false) > 0 ? (
        <div className="mb-3">
          <Button onClick={handleAllClick} variant="secondary">
            Acknowledge All
          </Button>
        </div>
      ) : (
        <span></span>
      )}

      <div>
        {notificationItems.map((notification) => (
          <>
            {notification.message && notification.is_read == readStatus ? (
              <Alert
                show={true}
                variant={
                  notification.severity === "Error" ? "danger" : "warning"
                }
              >
                <p style={{ wordBreak: "break-word" }}>
                  <Moment format="YYYY/MM/DD">{notification.created_at}</Moment>
                  - {notification.message}
                </p>
                <hr />
                {!notification.is_read ? (
                  <div className="d-flex justify-content-end">
                    <Button
                      id={notification.id}
                      onClick={(e) => handleClick(e, notification.severity)}
                      variant="outline-secondary"
                      type={"submit"}
                      value={notification.message}
                    >
                      Acknowledge
                    </Button>
                  </div>
                ) : (
                  <>
                    <div className="d-flex justify-content-start">
                      {/* Acknowledged by :{" "}
                      {notification.updated_by
                        ? notification.updated_by
                        : "Unknown"}
                    </div>
                    <div> */}
                      Acknowledged On :{" "}
                      <Moment format="YYYY/MM/DD">
                        {notification.updated_at}
                      </Moment>
                    </div>
                  </>
                )}
              </Alert>
            ) : (
              <p></p>
            )}
          </>
        ))}
      </div>

      {(title === "Active Notifications" && notificationCount(false) > 0) ||
      (title === "Past Notifications" && notificationCount(true) > 0) ? (
        <Paginator
          page={page}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      ) : (
        <span></span>
      )}
    </>
  );
}

export default Notifications;
