import { DetailsList, IDetailsListCheckboxProps, IRenderFunction, Selection, SelectionMode } from '@fluentui/react';
import { useEffect, useMemo, useState } from 'react';
import { getCameras } from '../services/Camera';
import { CameraItem } from '../models/Camera';
import Videos from './Media';
import { detailsListCustomStyles } from './styles/styles';
import { Card } from 'react-bootstrap';
import Spinner from './Spinner';
import { useSelectedMediaContext } from '../context/MediaContext';

interface CameraItemRendererProps {
  item: CameraItem;
  selection: Selection;
}

const CameraItemRenderer: React.FC<CameraItemRendererProps> = ({ item, selection }) => {
  return (
    <div
      id={`camera-label-${item.id}`}
      onClick={() => selection.toggleKeySelected(item.camera_id)}
      style={{
        cursor: 'pointer',
        fontSize: 14,
        fontWeight: 500,
        whiteSpace: 'normal',
        textOverflow: 'clip',
        lineHeight: 'normal'
      }}
    >
      {item.camera_id}
    </div>
  );
};

interface CameraProps {
  groupId?: number;
  onCamerasLoaded: (loadedCameras: CameraItem[]) => void
}

const Cameras: React.FC<CameraProps> = ({ groupId, onCamerasLoaded }) => {
  const [cameraItems, setCameraItems] = useState<CameraItem[]>([]);
  const [selectedItems, setSelectedItems] = useState<CameraItem[]>([]);
  const [loading, setLoading] = useState(true);
  const { setSelectedMedia } = useSelectedMediaContext();

  const loadCameras = () => {
    setLoading(true);
    const getCameras$ = getCameras({ group: groupId, page: "all" });
    const sub = getCameras$.subscribe({
      next: (res) => {
        const items = res?.map((item: CameraItem) => ({
          id: item.id,
          camera_id: item.camera_id,
        }));
        onCamerasLoaded(items);
        setCameraItems(items)
        setLoading(false);
      },
      error: (error) => {
        console.error('Error loading cameras:', error);
        setLoading(false);
      },
    });
    return () => sub.unsubscribe();
  };

  useEffect(() => {
    loadCameras();
  }, [groupId]);

  const columns = [
    {
      key: 'cameras',
      name: 'CAMERAS',
      fieldName: 'camera',
      minWidth: 210,
      maxWidth: 350,
      isResizable: false,
      onRender: (item: CameraItem, _index?: number) => {
        return <CameraItemRenderer item={item} selection={selection as Selection} />;
      },
    },
  ];

  const selection = useMemo(
    () =>
      new Selection({
        onSelectionChanged: () => {
          setSelectedItems(selection.getSelection() as CameraItem[]);
        },
      }),
    []
  );

  const onRenderCheckbox: IRenderFunction<IDetailsListCheckboxProps> = (props) => {
    return <input type="checkbox" id={"camera-checkboxes"} {...props} readOnly checked={props?.checked} />;
  }

  useEffect(() => {
    // Set up the selection when selection or cameraItems change
    selection.setItems(cameraItems as any, true);
    selection.setIndexSelected(0, true, false);

    // Update the onSelectionChanged handler when the selection object changes
    selection.setChangeEvents(true);

    return () => {
      // Cleanup to avoid memory leaks
      selection.setChangeEvents(false);
    };
  }, [selection, cameraItems]);


  useEffect(() => {
    // Reset the selected media state to null when there are no camera items or selected items
    if (!(cameraItems && cameraItems.length > 0 && selectedItems && selectedItems.length > 0)) {
      setSelectedMedia(null);
    }
  }, [cameraItems, selectedItems]);


  return (
    <>
      {loading ? <Spinner /> :
        <div>
          {cameraItems && cameraItems.length > 0 && (
            <Card className="shadow-sm rounded border-1 p-1 koala-card">
              <Card.Body className='px-0 pt-0'>
                <div className='' style={{ maxHeight: '400px', overflowY: 'auto' }}>
                  <DetailsList
                    items={cameraItems}
                    columns={columns}
                    selection={selection}
                    setKey="multiple"
                    isHeaderVisible={true}
                    selectionMode={SelectionMode.multiple}
                    onRenderCheckbox={onRenderCheckbox}
                    compact
                    styles={detailsListCustomStyles}
                    selectionPreservedOnEmptyClick={true}
                  />
                </div>
              </Card.Body>
            </Card >)}

          {cameraItems && cameraItems.length > 0 && selectedItems && selectedItems.length > 0 &&
            <Videos selectedCameras={selectedItems} groupId={groupId} />
          }
        </div>
      }
    </>
  );
};

export default Cameras;
