import {
  DetailsList,
  IDetailsListCheckboxProps,
  IRenderFunction,
  IGroup,
  Label,
  Selection,
  SelectionMode,
} from "@fluentui/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getMedias } from "../services/Media";
import { MediaItem } from "../models/Media";
import { CameraItem } from "../models/Camera";
import { useSelectedMediaContext } from "../context/MediaContext";
import { detailsListCustomStyles } from "./styles/styles";
import { Card, Form } from "react-bootstrap";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import IdentifiedSpecies from "./IdentifiedSpecies";
import { useSelectedIdentifiedObjectContext } from "../context/IdentifiedObjectContext";

initializeIcons();

interface MediaProps {
  selectedCameras: CameraItem[];
  groupId?: number;
}

interface MediaGroup extends IGroup {
  key: string;
  name: string;
  startIndex: number;
  count: number;
  level: number;
  isCollapsed: boolean;
}

const Media: React.FC<MediaProps> = ({ selectedCameras, groupId }) => {
  const [mediaItems, setMediaItems] = useState<MediaItem[]>([]);
  const [mediaByDate, setMediaByDate] = useState<Map<string, MediaItem[]>>();
  const [mediaGroups, setMediaGroups] = useState<MediaGroup[]>([]);

  const { setSelectedMedia } = useSelectedMediaContext();
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const { selectedIdentifiedObjects } = useSelectedIdentifiedObjectContext();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [page, setPage] = useState(2);

  const prevMediaItemsRef = useRef(mediaItems);
  const observerTarget = useRef(null);

  const loadMedia = useCallback(() => {
    const selectedCameraIds = selectedCameras.map((camera) => camera.id);
    const getMedias$ = getMedias(
      selectedCameraIds,
      startDate,
      endDate,
      groupId,
      selectedIdentifiedObjects
    );

    const sub = getMedias$.subscribe({
      next: (res: any) => {
        const items = res.results?.map((item: MediaItem) => ({
          id: item.id,
          title: item.title,
          url: item.url,
          specie: item.specie,
          media_file: item.media_file,
          media_type: item.media_type,
          date: item.date,
          latest_ai_model_feedback: item.latest_ai_model_feedback,
          all_ai_model_feedback: item.all_ai_model_feedback,
          ai_model: item.ai_model,
          latest_ai_model_identified_species_counts:
            item.latest_ai_model_identified_species_counts,
          all_ai_model_identified_species_counts:
            item.all_ai_model_identified_species_counts,
        }));
        setMediaItems(items);
      },
    });
  }, [selectedCameras, startDate, endDate, groupId, selectedIdentifiedObjects]);

  const loadMoreMedia = useCallback(() => {
    if (!isLoading) {
      setIsLoading(true);
      const selectedCameraIds = selectedCameras.map((camera) => camera.id);
      const getMedias$ = getMedias(
        selectedCameraIds,
        startDate,
        endDate,
        groupId,
        selectedIdentifiedObjects,
        page
      );

      const sub = getMedias$.subscribe({
        next: (res: any) => {
          const items = res.results?.map((item: MediaItem) => ({
            id: item.id,
            title: item.title,
            url: item.url,
            specie: item.specie,
            media_file: item.media_file,
            media_type: item.media_type,
            date: item.date,
            latest_ai_model_feedback: item.latest_ai_model_feedback,
            all_ai_model_feedback: item.all_ai_model_feedback,
            ai_model: item.ai_model,
            latest_ai_model_identified_species_counts:
              item.latest_ai_model_identified_species_counts,
            all_ai_model_identified_species_counts:
              item.all_ai_model_identified_species_counts,
          }));
          setMediaItems((prevItems) => [...prevItems, ...items]);
          setPage(page + 1);
          setIsLoading(false);
        },
        error: (e: any) => {
          // Check if no more pages
          setIsLoading(false);
          // Ignore 404's most likely due to no more pages
          if (e.status === 404) {
            return;
          }
          setError(e);
        },
        complete: () => setIsLoading(false),
      });
    }
  }, [
    isLoading,
    selectedCameras,
    startDate,
    endDate,
    groupId,
    selectedIdentifiedObjects,
    page,
  ]);

  useEffect(() => {
    //Reset media state
    setPage(2);
    setError(null);
    setIsLoading(false);
    // Load fresh media
    loadMedia();
  }, [selectedCameras, startDate, endDate, groupId, selectedIdentifiedObjects]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          if (!isLoading) {
            loadMoreMedia();
          }
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, mediaItems]);

  // Group media by date
  useEffect(() => {
    const tempMediaByDate = new Map<string, MediaItem[]>();
    mediaItems.forEach((media) => {
      const mediaDate = media?.date?.toString()?.split("T")[0];
      if (tempMediaByDate.has(mediaDate)) {
        tempMediaByDate.get(mediaDate)?.push(media);
      } else {
        tempMediaByDate.set(mediaDate, [media]);
      }
    });
    setMediaByDate(tempMediaByDate);
  }, [mediaItems]);

  // Render media grouped by date
  useEffect(() => {
    let cumulativeMediaCount = 0;
    if (mediaByDate) {
      const tempMediaGroups = Array.from(mediaByDate.entries()).map(
        ([date, media]) => {
          const group = {
            key: date,
            name: date,
            startIndex: cumulativeMediaCount,
            count: media.length,
            level: 0,
            isCollapsed: true,
          };
          cumulativeMediaCount += media.length;
          return group;
        }
      );
      setMediaGroups(tempMediaGroups);
    }
  }, [mediaByDate]);

  const columns = [
    {
      key: "title",
      name: "VIDEOS",
      fieldName: "title",
      minWidth: 160,
      maxWidth: 150,
      isResizable: false,
      onRender: (item: MediaItem) => {
        return (
          <div
            id={`media-label-${item.id}`}
            onClick={() => {
              selection.toggleKeySelected(item.title);
            }}
            style={{
              cursor: "pointer",
              fontSize: 14,
              fontWeight: 500,
              whiteSpace: "normal",
              textOverflow: "clip",
              lineHeight: "normal",
            }}
          >
            {item.title}
          </div>
        );
      },
    },
  ];

  const selection = useMemo(
    () =>
      new Selection({
        onSelectionChanged: () => {
          const selectedMedia =
            (selection.getSelection() as MediaItem[]) ?? null;
          setSelectedMedia(selectedMedia.length > 0 ? selectedMedia[0] : null);
        },
        selectionMode: SelectionMode.single,
      }),
    []
  );

  useEffect(() => {
    if (mediaItems.length > 0) {
      // Check if mediaItems has changed
      if (prevMediaItemsRef.current !== mediaItems) {
        selection.setIndexSelected(0, true, false);
        // Update the ref with the current value
        prevMediaItemsRef.current = mediaItems;
      }
      if (mediaItems.length > 0 && !selection.isIndexSelected(0)) {
        selection.setIndexSelected(0, true, false);
      }
    } else {
      setSelectedMedia(null);
    }
  }, [mediaItems]);

  const onRenderCheckbox: IRenderFunction<IDetailsListCheckboxProps> = (
    props
  ) => {
    return (
      <input
        id={"media-checkboxes"}
        type="checkbox"
        {...props}
        readOnly
        checked={props?.checked}
      />
    );
  };

  return (
    <div className="mt-2">
      <Card
        border="light"
        className="shadow-sm rounded border-1 p-1 koala-card mt-2"
      >
        <Card.Body className="py-0">
          <Label>DATE RANGE</Label>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                <small>Start Date:</small>
              </Form.Label>
              <Form.Control
                type="date"
                name="datepic"
                className="mx-2 p-2"
                placeholder="DateRange"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>
                <small>End Date:</small>
              </Form.Label>
              <Form.Control
                className="mx-2 p-2"
                type="date"
                name="datepic"
                placeholder="DateRange"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
      <IdentifiedSpecies mode={"multi"} />
      {mediaItems && mediaItems.length > 0 ? (
        <Card
          border="light"
          className="shadow-sm rounded border-1 p-1 koala-card mt-2"
        >
          <Card.Body className="px-0 pt-0">
            <div style={{ maxHeight: "300px", overflowY: "auto" }}>
              <DetailsList
                className="mt-0"
                groups={mediaGroups}
                items={mediaItems}
                columns={columns}
                selection={selection}
                setKey="single"
                isHeaderVisible={true}
                selectionMode={SelectionMode.single}
                onRenderCheckbox={onRenderCheckbox}
                compact
                styles={detailsListCustomStyles}
                selectionPreservedOnEmptyClick={true}
                onRenderDetailsFooter={() => (
                  <>
                    <div ref={observerTarget}></div>
                    {isLoading && <p>Loading...</p>}
                    {error && <p>Failed to load more: {error.message}</p>}
                  </>
                )}
              />
            </div>
          </Card.Body>
        </Card>
      ) : (
        <div>
          <Card
            border="light"
            className="shadow-sm rounded border-1 p-1 koala-card mt-2"
          >
            <Card.Body>
              No media available for selected Camera or date range. Please try
              selecting another camera or consider changing the selected date
              range or clearing the date range..
            </Card.Body>
          </Card>
        </div>
      )}
    </div>
  );
};

export default Media;
