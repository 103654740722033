import { baseURL } from "./ServiceUtil";
import {
  EditGroupItem,
  EditGroupPropItem,
  PostGroupItem,
} from "../models/Group";
import { customAjax } from "../components/ErrorHandler";
import fetchData from "../utils/getAllPaginated";

export const getGroups = (options?: {
  userGroups?: boolean;
  visibility?: boolean;
}): Promise<any> => {
  let queryParams = "";

  // Add logic to include user_groups parameter
  if (options?.userGroups) {
    queryParams += `${queryParams ? "&" : ""}user_groups=${
      options?.userGroups
    }`;
  }

  // Add logic to include group_visibility parameter
  if (options?.visibility) {
    queryParams += `&group_visibility=${options.visibility}`;
  }

  // const url = `${baseURL}/groups${queryParams ? "?" + queryParams : ""}`;
  const url = `${baseURL}/groups`;
  return fetchData(url, queryParams);
};

export const postGroup = (groupPayload: PostGroupItem) =>
  customAjax(`${baseURL}/groups`, "POST", groupPayload);

export const editGroup = (groupId: number, groupPayload: EditGroupItem) =>
  customAjax(`${baseURL}/groups/${groupId}`, "PUT", groupPayload);

export const editGroupProp = (
  groupId: number,
  groupPayload: EditGroupPropItem
) => {
  console.log("call to", `${baseURL}/group_properties/${groupId}`);
  return customAjax(
    `${baseURL}/group_properties/${groupId}`,
    "PUT",
    groupPayload
  );
};

// export const editGroupVisibility = (
//   groupId: number,
//   groupPayload: EditVisibiltyGroupItem
// ) => customAjax(`${baseURL}/groups/${groupId}`, "PUT", groupPayload);
