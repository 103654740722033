import { Row } from "react-bootstrap";
import PageLayout from "../components/PageLayout";
import Notifications from "../components/Notifications";

const ViewNotifications = () => {
  return (
    <PageLayout title="View Past Notifications">
      <>
        <Row className="mb-2">
          <Notifications title="Past Notifications" />
        </Row>
      </>
    </PageLayout>
  );
};

export default ViewNotifications;
