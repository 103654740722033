import { Col, ListGroup, Row } from "react-bootstrap";
import PageLayout from "../components/PageLayout";
import { useContext, useEffect, useState } from "react";
import CustomModal from "../components/CustomModal";
import { getCameras } from "../services/Camera";
import { CameraItem } from "../models/Camera";
import AddEditCamera from "../components/AddEditCamera";
import { SignInContext } from "../context/SignInContext";
import Paginator from "../components/Paginator";
import Spinner from "../components/Spinner";

const ManageCameras = () => {
    const [cameras, setCameras] = useState<CameraItem[]>([])
    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [page, setPage] = useState<any>(null);
    const [formData, setFormData] = useState<any>(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [loading, setLoading] = useState(true);
    const { isAdminUser, userGroupsCount } = useContext(SignInContext)

    const loadCameras = () => {
        setLoading(true)
        const getCameras$ = getCameras({ page: pageNumber });
        const sub = getCameras$.subscribe({
            next: (res: any) => {
                setPage(res);
                const cameraResults = res.results
                const camerasWithoutGPS = cameraResults.filter((camera: { has_gps: boolean; }) => camera.has_gps === false);
                isAdminUser ? setCameras(cameraResults) : setCameras(camerasWithoutGPS)
                setLoading(false)
            },
        });
        return () => sub.unsubscribe();
    };

    useEffect(() => {
        userGroupsCount != null && loadCameras();
    }, [pageNumber]);


    return (
        <PageLayout title="Manage Cameras">
            <CustomModal
                show={showAddModal}
                scrollable={false}
                modalClassName={'add-camera-entry-modal-dialog'}
                modalBody={<AddEditCamera
                    formData={formData}
                    onClose={() => {
                        setShowAddModal(false);
                        setFormData(null);
                        loadCameras()
                    }}
                />}
            />

            {userGroupsCount == null || loading ? <Spinner /> :
                <>
                    {isAdminUser &&
                        <Row>
                            <Col xl={12} lg={12} md={12} sm={12} className="d-flex justify-content-end">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => setShowAddModal(true)}
                                >
                                    + Add Camera
                                </button>
                            </Col>
                        </Row>
                    }

                    <Row className="mt-2">
                        <ListGroup>
                            {cameras.map((camera) => (

                                <ListGroup.Item
                                    className="d-flex justify-content-between align-items-start"
                                    key={camera.id}
                                >
                                    <div className="ms-2 me-auto p-0">
                                        <div className="">
                                            <span className="fw-bold">{camera.camera_id}</span>
                                            {camera.group_name
                                                ? <div><small id={`group-${camera.id}`}>Managed by {camera.group_name} group</small></div>
                                                : <div><small id={`no-group-${camera.id}`}>No group assigned yet</small></div>
                                            }
                                            {camera.region_name
                                                ? <div><small id={`region-${camera.id}`}>Located in {camera.region_name.charAt(0).toUpperCase() + camera.region_name.slice(1).toLowerCase()} region</small></div>

                                                : <div><small id={`no-region-${camera.id}`}>No location assigned yet</small></div>
                                            }
                                        </div>
                                    </div>
                                    <button
                                        type="button"
                                        className="btn list-action-button"
                                        onClick={() => {
                                            setFormData(camera);
                                            setShowAddModal(true)
                                        }}
                                    >
                                        <i className="bi bi-pencil-square" id={`edit-camera-entry-${camera.id}`}></i>
                                    </button>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Row>
                    <Paginator page={page} pageNumber={pageNumber} setPageNumber={setPageNumber} />
                </>
            }
        </PageLayout>

    )
}

export default ManageCameras;