import { useEffect, useState } from "react";
import { Card, Col, Row, Modal, Alert, Button } from "react-bootstrap";
import {
  ActivityItem,
  IChoiceGroupOption,
  ITheme,
  Icon,
  Label,
  getFocusStyle,
  getTheme,
  mergeStyleSets,
} from "@fluentui/react";
import { useSelectedMediaContext } from "../context/MediaContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import {
  PostMediaAnalysisItem,
  MediaAnalysisItem,
  MediaItem,
  AIModelInfoItem,
} from "../models/Media";
import {
  getMediaById,
  postMediaAnalysis,
  updateMediaAnalysis,
} from "../services/Media";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { useBoolean } from "@fluentui/react-hooks";
import MediaAnalysisForm from "./MediaAnalysisForm";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import placeholder from "../assets/images/noresults.jpg";
import { getCommunityFeedbackOptions } from "../services/CommunityFeedback";
import fetchData from "../utils/getAllPaginated";
import Select from "react-select";

const MediaAnalysis = () => {
  const { selectedMedia } = useSelectedMediaContext();
  const optionsSave: IChoiceGroupOption[] = [
    {
      key: "true",
      text: "AI Accurate",
      id: "MediaAnalysisFormchoicesSaveAccurate",
    },
    {
      key: "false",
      text: "AI Inaccurate",
      id: "MediaAnalysisFormchoicesSaveInAccurate",
    },
  ];
  const optionsUpdate: IChoiceGroupOption[] = [
    {
      key: "true",
      text: "AI Accurate",
      id: "MediaAnalysisFormchoicesUpdateAccurate",
    },
    {
      key: "false",
      text: "AI Inaccurate",
      id: "MediaAnalysisFormchoicesUpdateInAccurate",
    },
  ];

  const [aiVersions, setAiVersions] = useState<AIModelInfoItem[] | null>(null);
  const [selectedAiVersions, setSelectedAiVersions] =
    useState<AIModelInfoItem | null>(null);
  const [aiModelFeedback, setAiModelFeedback] = useState<
    MediaAnalysisItem[] | null
  >(null);
  const [selectedNote, setSelectedNote] = useState<MediaAnalysisItem | null>(
    null
  );
  const [selectedMediaAnalysisData, setSelectedMediaAnalysisData] =
    useState<MediaAnalysisItem | null>(null);
  const [selectedMediaData, setSelectedMediaData] = useState<MediaItem | null>(
    selectedMedia
  );
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const [aiModelInfo, setAiModelInfo] = useState({
    name: "",
    version: "",
    url: "",
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [selectedNoteIndex, setSelectedNoteIndex] = useState<number>(0);
  const [hasEditPermission, setHasEditPermission] = useState<boolean>(false);

  const theme: ITheme = getTheme();
  const classNames = mergeStyleSets({
    container: {
      overflow: "auto",
      maxHeight: 500,
    },
    itemCell: [
      getFocusStyle(theme, { inset: -1 }),
      {
        minHeight: 54,
        padding: 10,
        margin: 0,
        boxSizing: "border-box",
        borderBottom: `1px solid lightgrey`,
        display: "flex",
      },
    ],
  });

  useEffect(() => {
    fetchData("/api/v1/ai_model_info").then((ai_models) => {
      let mapped_data = ai_models.map((model: AIModelInfoItem) => {
        return { ...model, value: model.name, label: model.version };
      });
      setAiVersions(mapped_data);
      if (mapped_data.length) {
        setSelectedAiVersions(mapped_data[mapped_data.length - 1]);
      }
    });
  }, []);
  useEffect(() => loadMedia(), []);
  useEffect(() => {
    if (selectedMedia) {
      setSelectedMediaData(selectedMedia);
      console.log("selectedMedia", selectedMedia);
      if (selectedMedia.all_ai_model_feedback?.length) {
        setAiModelFeedback(
          selectedMedia.all_ai_model_feedback.filter(
            (item) => item.ai_model === selectedAiVersions?.url
          )
        );
      } else {
        setAiModelFeedback([]);
      }
      setAiModelInfo({
        name: selectedAiVersions?.name || "",
        version: selectedAiVersions?.version || "",
        url: selectedAiVersions?.url || "",
      });
    }
  }, [selectedMedia, selectedAiVersions]);

  useEffect(() => loadMedia(), []);
  useEffect(() => {
    setSelectedNote(
      aiModelFeedback?.length ? aiModelFeedback[selectedNoteIndex] : null
    );
  }, [aiModelFeedback, selectedNoteIndex]);

  const loadMedia = () => {
    if (selectedMediaData?.id) {
      const getMedia$ = getMediaById(selectedMediaData?.id);
      const sub = getMedia$.subscribe({
        next: (res) => {
          setSelectedMediaData(res);
          if (res.all_ai_model_feedback?.length) {
            setAiModelFeedback(
              res.all_ai_model_feedback.filter(
                (item: any) => item.ai_model === selectedAiVersions?.url
              )
            );
          } else {
            setAiModelFeedback([]);
          }
        },
      });
      return () => sub.unsubscribe();
    }
  };

  const saveMediaAnalysis = (
    hasAICorrectlyDetected: boolean,
    comment: string | undefined,
    id: number | null
  ): (() => void) => {
    if (selectedMediaData?.url && !id) {
      const toastId = toast.loading("Please wait...");
      let payload: PostMediaAnalysisItem = {
        comment: !hasAICorrectlyDetected && comment ? comment : "",
        media: selectedMediaData.id,
        has_ai_correctly_detected: hasAICorrectlyDetected,
        ai_model: aiModelInfo.url,
      };
      const postMediaAnalysisItem$ = postMediaAnalysis(payload);
      const sub = postMediaAnalysisItem$.subscribe({
        next: () => {
          toast.update(toastId, {
            render: "Media notes saved successfully.",
            type: "success",
            isLoading: false,
            autoClose: 3000,
            closeButton: true,
            hideProgressBar: false,
            theme: "colored",
          });
          loadMedia();
        },
        error: (e) => {
          toast.update(toastId, {
            render: "Error! Something went wrong",
            type: "error",
            isLoading: false,
            autoClose: 3000,
            closeButton: true,
            hideProgressBar: false,
          });
          if (e.status == 400) {
            const errorResponse = e.response;
            if (errorResponse?.non_field_errors?.length > 0) {
              setErrorMessage(errorResponse.non_field_errors[0]);
              setShowErrorMessage(true);
            }
          }
          console.error("Error", e);
        },
      });
      return () => sub.unsubscribe();
    }

    if (selectedMediaData?.url && id) {
      const toastId = toast.loading("Please wait...");
      let payload: PostMediaAnalysisItem = {
        comment: !hasAICorrectlyDetected && comment ? comment : "",
        media: selectedMediaData.id,
        has_ai_correctly_detected: hasAICorrectlyDetected,
        id: id,
        ai_model: aiModelInfo.url,
      };
      const updateMediaAnalysisItem$ = updateMediaAnalysis(payload);
      const sub = updateMediaAnalysisItem$.subscribe({
        next: () => {
          hideModal();
          toast.update(toastId, {
            render: "Media notes updated successfully.",
            type: "success",
            isLoading: false,
            autoClose: 3000,
            closeButton: true,
            hideProgressBar: false,
            theme: "colored",
          });
          loadMedia();
        },
        error: (e) => {
          toast.update(toastId, {
            render: "Error! Something went wrong",
            type: "error",
            isLoading: false,
            autoClose: 3000,
            closeButton: true,
            hideProgressBar: false,
          });
          if (e.status == 400) {
            const errorResponse = e.response;
            if (errorResponse?.non_field_errors?.length > 0) {
              setErrorMessage(errorResponse.non_field_errors[0]);
              setShowErrorMessage(true);
            }
          }
          console.error("Error", e);
        },
      });
      return () => sub.unsubscribe();
    }
    return () => {};
  };

  const getUserNote = (analysis: MediaAnalysisItem) => {
    return (
      <>
        <div>
          AI Accurate: {analysis.has_ai_correctly_detected ? "True" : "False"}
        </div>
        {analysis.comment && <div>Note: {analysis.comment}</div>}
      </>
    );
  };

  const handlePreviousNote = () => {
    setSelectedNoteIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNextNote = () => {
    const maxIndex = aiModelFeedback?.length ? aiModelFeedback?.length - 1 : 0;
    setSelectedNoteIndex((prevIndex) => Math.min(prevIndex + 1, maxIndex));
  };

  useEffect(() => {
    if (selectedNote && selectedNote.id) {
      checkEditPermission(selectedNote.id);
    }
  }, [selectedNote]);

  const checkEditPermission = (id: number) => {
    const getCommunityFeedbackOptions$ = getCommunityFeedbackOptions(id);
    const sub = getCommunityFeedbackOptions$.subscribe({
      next: (res: any) => {
        setHasEditPermission(!!res.actions?.PUT);
      },
    });
    return () => sub.unsubscribe();
  };

  return (
    <>
      {selectedMedia &&
        selectedMedia.latest_ai_model_identified_species_counts?.length > 0 && (
          <>
            <Card border="light" className="shadow-sm">
              <Card.Body>
                <Row className="d-block d-flex align-items-center">
                  <Col
                    xs={12}
                    xl={8}
                    className="align-items-center justify-content-xl-center"
                  >
                    <div className="d-sm-block">
                      <h5>AI Identified</h5>

                      <h6 className="mb-2">{aiModelInfo.name}</h6>
                      <h6 className="mb-2">
                        Version
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={
                            aiVersions?.length
                              ? aiVersions[aiVersions.length - 1]
                              : undefined
                          }
                          isDisabled={!aiVersions?.length}
                          isLoading={!aiVersions?.length}
                          onChange={setSelectedAiVersions}
                          //isClearable={isClearable}
                          //isRtl={isRtl}
                          //isSearchable={isSearchable}
                          name="ai_model_version"
                          options={aiVersions ? (aiVersions as any) : undefined}
                        />
                      </h6>
                    </div>
                    {selectedMediaData?.all_ai_model_identified_species_counts?.map(
                      (object, index) => {
                        if (object.ai_model === selectedAiVersions?.url) {
                          return (
                            <div key={index + object.identified_object_name}>
                              <small>{object.identified_object_name}</small>
                            </div>
                          );
                        }
                        return null;
                      }
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card border="light" className="shadow-sm mt-2">
              <Card.Body>
                <Row>
                  {aiModelFeedback?.length !== 0 && (
                    <Label style={{ fontSize: "16px" }}>
                      Media Analysis Notes
                    </Label>
                  )}
                  {aiModelFeedback?.length === 0 && (
                    <Label>No notes added.</Label>
                  )}
                  {selectedNote && (
                    <div
                      className={classNames.itemCell}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <ActivityItem
                          comments={getUserNote(selectedNote)}
                          activityDescription={`${selectedNote.created_by} added`}
                          timeStamp={moment(selectedNote.created_at).fromNow()}
                          activityIcon={<Icon iconName={"Message"} />}
                        />
                      </div>
                      {hasEditPermission && (
                        <div>
                          <FontAwesomeIcon
                            icon={faEdit}
                            onClick={() => {
                              setSelectedMediaAnalysisData(selectedNote);
                              showModal();
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </Row>
                {selectedNote && (
                  <Row className="mt-2">
                    <Col xs={6} className="d-flex justify-content-start">
                      <Button
                        size="sm"
                        variant="primary"
                        onClick={handlePreviousNote}
                        disabled={selectedNoteIndex === 0}
                      >
                        Previous
                      </Button>
                    </Col>
                    <Col xs={6} className="d-flex justify-content-end">
                      <Button
                        size="sm"
                        variant="primary"
                        onClick={handleNextNote}
                        disabled={
                          selectedNoteIndex ===
                          (aiModelFeedback?.length || 0) - 1
                        }
                      >
                        Next
                      </Button>
                    </Col>
                  </Row>
                )}
              </Card.Body>
            </Card>

            <Card border="light" className="shadow-sm mt-2">
              <Card.Body>
                <Label style={{ fontSize: "16px" }}>Add Your Analysis</Label>
                {showErrorMessage && (
                  <Alert
                    variant="danger"
                    onClose={() => setShowErrorMessage(false)}
                    dismissible
                  >
                    {errorMessage}
                  </Alert>
                )}
                <MediaAnalysisForm
                  options={optionsSave}
                  onSave={saveMediaAnalysis}
                  saveButtonText="Save"
                />
              </Card.Body>
            </Card>
          </>
        )}

      {selectedMedia?.latest_ai_model_identified_species_counts?.length ===
        0 && (
        <div>
          <Card border="light" className="shadow-sm">
            <Card.Body
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{ maxWidth: "100%", margin: "auto" }}>
                <img
                  src={placeholder}
                  alt="Placeholder"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
              <h6 style={{ marginBottom: "1rem", textAlign: "center" }}>
                No AI results available for this media.
              </h6>
            </Card.Body>
          </Card>
        </div>
      )}
      <Modal show={isModalOpen} backdrop="static" onHide={hideModal}>
        <Modal.Header>
          <Modal.Title>Edit Media Analysis</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MediaAnalysisForm
            options={optionsUpdate}
            onSave={saveMediaAnalysis}
            onCancel={hideModal}
            cancelButtonText="Cancel"
            data={selectedMediaAnalysisData}
            saveButtonText="Update"
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MediaAnalysis;
