import { Container } from "react-bootstrap";
import { FieldValues, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { User } from "../models/User";
import { toast } from "react-toastify";
import { editGroup } from "../services/Groups";
import { EditGroupItem } from "../models/Group";
import LabelAndInput from "./LabelAndInput";
import ErrorAlertFor400 from "./ErrorAlertFor400";

const HideUnhideGroup = ({
  onClose,
  formData,
}: // groupVisibility,

{
  onClose: () => void;
  formData?: any;
}) => {
  const { register, handleSubmit, setValue, setError } = useForm();
  const [APIErrors, setAPIErrors] = useState(null);
  const [groupVisibility, setGroupVisibility] = useState<boolean>(true);

  const mapUsersToOptions = (
    users: User[]
  ): { value: number; label: string }[] => {
    return users.map((user: User) => ({
      value: user.id,
      label: `${user.first_name} ${user.last_name} ${user.username}`,
    }));
  };
  useEffect(() => {
    if (formData) {
      const users = formData.users;
      const userOptions = mapUsersToOptions(users);
      setValue("selectedUsers", userOptions);
      setValue("name", formData?.name);
      setValue("id", formData?.id);
    }
  }, [formData, setValue]);

  useEffect(() => {
    if (formData.groupprop.is_visible === true) setGroupVisibility(false);
    else setGroupVisibility(true);
  }, [formData, setValue]);

  const updateGroup = (data: FieldValues) => {
    if (!data.name) {
      setError("name", { type: "focus" }, { shouldFocus: true });
    } else {
      const toastId = toast.loading("Please wait...");
      const selectedUserIds = data.selectedUsers?.map(
        (option: { value: any }) => option.value
      );

      const payload: EditGroupItem = {
        id: data.id,
        name: data.name,
        users: selectedUserIds ? selectedUserIds : [],
        groupprop: {
          id: formData.groupprop.id,
          group: data.id,
          is_visible: !formData.groupprop.is_visible,
        },
      };

      const editGroup$ = editGroup(data.id, payload);
      const sub = editGroup$.subscribe({
        next: (res) => {
          toast.update(toastId, {
            render: "Group has been updated successfully.",
            type: "success",
            isLoading: false,
            autoClose: 3000,
            closeButton: true,
            hideProgressBar: false,
            theme: "colored",
          });
          onClose();
        },
        error: (e) => {
          toast.update(toastId, {
            render: "Error! Something went wrong",
            type: "error",
            isLoading: false,
            autoClose: 3000,
            closeButton: true,
            hideProgressBar: false,
          });
          if (e.status == 400) {
            setAPIErrors(e.response);
          }
          console.error("Error", e);
        },
      });
      return () => sub.unsubscribe();
    }
  };

  const onSubmit = (data: FieldValues) => {
    formData && updateGroup(data);
  };

  return (
    <Container>
      {APIErrors && <ErrorAlertFor400 APIErrorMessages={APIErrors} />}
      <div className="col-auto">
        <div className="row mb-3 mt-3">
          <div className="col-md-5 d-flex align-items-center">
            <h4 className="mb-0">
              {groupVisibility == false ? "Hide Group" : "Unhide Group"}
            </h4>
          </div>
          <div className="col-md-7 float-end text-end">
            <button
              type="button"
              onClick={onClose}
              className="btn btn-secondary mx-2"
            >
              Cancel
            </button>
            {formData && (
              <button
                type="button"
                onClick={handleSubmit(onSubmit)}
                className="btn btn-primary mx-2"
              >
                {"Confirm"}
              </button>
            )}
          </div>
        </div>
        <hr />
        <div className="col-md-12 mb-4 mt-4">
          <LabelAndInput
            label={
              groupVisibility == false
                ? "Confirm to Hide the group"
                : "Confirm to Unhide the group"
            }
            register={register}
            registerName="name"
            rules={{ required: "This field is required" }}
            handleFocus={() => setAPIErrors(null)}
            readOnly={false}
          />
        </div>
      </div>
    </Container>
  );
};

export default HideUnhideGroup;
