import {
  DetailsList,
  IColumn,
  IDetailsListCheckboxProps,
  IRenderFunction,
  Selection,
  SelectionMode,
} from "@fluentui/react";
import { useEffect, useMemo, useState } from "react";
import { RegionItem } from "../models/Region";
import { Card } from "react-bootstrap";
import { detailsListCustomStyles } from "./styles/styles";
import { getIdentifiedObjects } from "../services/Media";
import { IdentifiedObjectItem } from "../models/Media";
import { useSelectedIdentifiedObjectContext } from "../context/IdentifiedObjectContext";

interface IdentifiedObjectProps {
  mode: string;
}
interface IdentifiedObjectItemRendererProps {
  item: IdentifiedObjectItem;
  selection: Selection;
}

const IdentifiedObjectItemRenderer: React.FC<
  IdentifiedObjectItemRendererProps
> = ({ item, selection }) => {
  return (
    <div
      id={`identified-species-label-${item.name}`}
      onClick={() => selection.toggleKeySelected(item.name)}
      style={{
        cursor: "pointer",
        fontSize: 14,
        fontWeight: 500,
      }}
    >
      {item.name}
    </div>
  );
};

const IdentifiedSpecies: React.FC<IdentifiedObjectProps> = ({ mode }) => {
  const [identifiedObjects, setIdentifiedObjects] = useState<
    IdentifiedObjectItem[]
  >([]);
  const { setSelectedIdentifiedObjects, setAllIdentifiedObjects } =
    useSelectedIdentifiedObjectContext();

  const loadIdentifiedObjects = () => {
    getIdentifiedObjects().then((items: any) => {
      setIdentifiedObjects(items);
      setAllIdentifiedObjects(items);
    });
    // const getIdentifiedObjects$ = getIdentifiedObjects();

    // const sub = getIdentifiedObjects$.subscribe({
    //   next: (res: any) => {
    //     setIdentifiedObjects(res?.results);
    //     setAllIdentifiedObjects(res?.results);
    //   },
    // });
    // return () => sub.unsubscribe();
  };

  useEffect(() => {
    loadIdentifiedObjects();
  }, []);

  const items = identifiedObjects?.map((item: IdentifiedObjectItem) => ({
    id: item.id,
    name: item.name,
  }));

  const columns: IColumn[] = [
    {
      key: "identifiedobjects",
      name: "IdentifiedSpecies",
      fieldName: "IdentifiedSpecies",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      onRender: (item: IdentifiedObjectItem, _index?: number) => {
        return (
          <IdentifiedObjectItemRenderer
            item={item}
            selection={selection as Selection}
          />
        );
      },
    },
  ];

  const selection = useMemo(
    () =>
      new Selection({
        onSelectionChanged: () => {
          const selectedLocations =
            (selection.getSelection() as RegionItem[]) ?? null;
          setSelectedIdentifiedObjects(selectedLocations);
        },
      }),
    []
  );

  const onRenderCheckbox: IRenderFunction<IDetailsListCheckboxProps> = (
    props
  ) => {
    return (
      <input
        id={"identified-species-checkboxes"}
        type="checkbox"
        {...props}
        readOnly
        checked={props?.checked}
      />
    );
  };

  return (
    <>
      <Card
        border="light"
        className="shadow-sm rounded border-1 p-1 koala-card mt-2"
      >
        <Card.Body className="px-0 pt-1">
          <div style={{ maxHeight: "192px", overflowY: "auto" }}>
            <DetailsList
              items={items}
              columns={columns}
              selection={selection}
              setKey="multiple"
              isHeaderVisible={true}
              selectionMode={
                mode === "single"
                  ? SelectionMode.single
                  : SelectionMode.multiple
              }
              onRenderCheckbox={onRenderCheckbox}
              compact
              styles={detailsListCustomStyles}
            />
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default IdentifiedSpecies;
