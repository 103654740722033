import { Accordion, Button, Col, Container, Form, Row } from "react-bootstrap";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { SyntheticEvent, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getGroups } from "../services/Groups";
import { GroupItem } from "../models/Group";
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  Marker,
  useMapEvents,
  useMap,
} from "react-leaflet";
import { PostCameraItem } from "../models/Camera";
import { postLocation } from "../services/Location";
import L, { LatLng, LatLngExpression, LeafletEvent } from "leaflet";
import { useUserLocationContext } from "../context/UserLocationContext";
import { postCamera, updateCamera } from "../services/Camera";
import LabelAndInput from "./LabelAndInput";
import SelectInput from "./SelectInput";
import { SignInContext } from "../context/SignInContext";
import ErrorAlertFor400 from "./ErrorAlertFor400";
import { AccordionEventKey } from "react-bootstrap/esm/AccordionContext";
import { PostLocationItem } from "../models/Location";
import { getRegions } from "../services/Region";

// Function to parse location point string into latitude and longitude
const parseLocationPoint = (point: string): [number, number] => {
  // Extract latitude and longitude from the point string
  const [longitude, latitude] = point
    .replace("SRID=4326;POINT (", "")
    .replace(")", "")
    .split(" ")
    .map(parseFloat);

  return [longitude, latitude] as [number, number];
};

// Location Marker component
const LocationMarker = ({ onLocationSelect, formData }: any) => {
  const [selectedLocation, setSelectedLocation] = useState<LatLng | null>(null);
  const map = useMap();
  const {
    useMyLocationClicked,
    resetUseMyLocationClick,
    setErrorMessage,
    resetErrorMessage,
  } = useUserLocationContext();

  const handleMarkerDragEnd = (e: LeafletEvent) => {
    const marker = e.target;
    const newLocation = marker.getLatLng();
    onLocationSelect(newLocation);
  };

  useEffect(() => {
    if (formData && formData.full_location && formData.full_location.point) {
      const locationPoint = parseLocationPoint(formData.full_location.point);
      const [longitude, latitude] = locationPoint;
      const markerLocation: LatLngExpression = [latitude, longitude];
      setSelectedLocation(L.latLng(latitude, longitude));
      map.flyTo(markerLocation, map.getZoom());
    }
  }, [formData]);

  const loadUserLocation = () => {
    map.locate().on("locationfound", function (e) {
      const userLocation = e.latlng;
      setSelectedLocation(userLocation);
      onLocationSelect(userLocation);
      resetUseMyLocationClick();
      resetErrorMessage();
      map.flyTo(userLocation, map.getZoom());
    });
    // The 'locationerror' event handles the case when location service is off
    map.on("locationerror", () => {
      setErrorMessage(
        'Location services are required to use the "Use My Location" feature.'
      );
    });
  };

  useEffect(() => {
    if (useMyLocationClicked) {
      loadUserLocation();
    }
  }, [useMyLocationClicked]);

  useMapEvents({
    click(e) {
      const clickedLocation = e.latlng;
      setSelectedLocation(clickedLocation);
      onLocationSelect(clickedLocation);
    },
  });

  return (
    <>
      {selectedLocation && (
        <Marker
          position={selectedLocation}
          draggable={true}
          eventHandlers={{
            dragend: handleMarkerDragEnd,
          }}
        />
      )}
    </>
  );
};

/**
 * ResizeMap component.
 * This component observes changes in the size of the map container and invalidates the map size accordingly.
 * It is useful for addressing issues where the map does not display properly when inside an element with dynamic size changes such as in our case Accordion.
 */
const ResizeMap: React.FC = () => {
  // Get the map instance from react-leaflet
  const map = useMap();

  useEffect(() => {
    // Create a ResizeObserver to detect changes in the size of the map container
    const resizeObserver = new ResizeObserver(() => {
      map.invalidateSize();
    });

    const container = document.getElementById("map-container");

    if (container) {
      resizeObserver.observe(container);
    }

    return () => {
      if (container) {
        resizeObserver.unobserve(container);
      }
    };
  }, [map]);

  return null;
};

// MapComponent
const MapComponent = ({
  locationsData,
  onLocationSelect,
  handleUseMyLocation,
  formData,
}: any) => {
  const mapCenter: [number, number] = [-27.4698, 153.0251];

  return (
    <MapContainer
      id="map-container"
      center={mapCenter}
      zoom={13}
      style={{ height: "40vh" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <GeoJSON data={locationsData}>
        <LocationMarker
          formData={formData}
          locationsData={locationsData}
          onLocationSelect={onLocationSelect}
          handleUseMyLocation={handleUseMyLocation}
        />
      </GeoJSON>
      <ResizeMap />
    </MapContainer>
  );
};

// AddEdit Camera component
const AddEditCamera = ({
  onClose,
  formData,
}: {
  onClose: () => void;
  formData?: any;
}) => {
  const [groups, setGroups] = useState<
    {
      value: number;
      label: string;
    }[]
  >([]);
  const { isAdminUser } = useContext(SignInContext);
  const {
    control,
    register,
    getValues,
    setValue,
    watch,
    formState: { errors },
    clearErrors,
    trigger,
  } = useForm();
  const [regionData, setLocationData] = useState<any>(null);
  const [geoJSONData, setGeoJSONData] = useState<any>(null);
  const userLocationContext = useUserLocationContext();
  const [APIErrors, setAPIErrors] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingGroups, setLoadingGroups] = useState(true);
  const [loadingRegions, setLoadingRegions] = useState(true);
  const [isAccordionCollapsed, setIsAccordionCollapsed] =
    useState<boolean>(false);

  // Watch for changes in the 'has_gps' checkbox
  const hasGpsValue = watch("has_gps");

  const onUseMyLocationClick = () =>
    userLocationContext.handleUseMyLocationClick();

  const mapUsersToOptions = (
    groups: GroupItem[]
  ): { value: number; label: string }[] => {
    return groups.map((group: GroupItem) => ({
      value: group.id,
      label: group.name,
    }));
  };

  const loadRegion = () => {
    getRegions().then((items) => {
      setLocationData(items);
      setLoadingRegions(false);
    });
  };
  const loadGroups = () => {
    getGroups({ userGroups: false }).then((items: any) => {
      const groupOptions = mapUsersToOptions(items);
      setGroups(groupOptions);
      setLoadingGroups(false);
    });
  };

  useEffect(() => {
    if (formData) {
      const selectedGroup = formData.group;
      if (selectedGroup && groups) {
        const selectedGroupOption = groups.find(
          (group) => group.value === selectedGroup
        );
        setValue("selectedGroup", selectedGroupOption);
      }
      setValue("camera_id", formData?.camera_id);
      setValue("has_gps", formData?.has_gps);

      if (formData?.full_location && formData?.full_location.point) {
        const locationPoint = parseLocationPoint(formData.full_location.point);
        const [longitude, latitude] = locationPoint;

        setValue("latitude", latitude);
        setValue("longitude", longitude);
      }
    }
  }, [formData, setValue, groups]);

  useEffect(() => {
    setLoading(true);
    setLoadingGroups(true);
    setLoadingRegions(true);
    loadGroups();
    loadRegion();
  }, []);

  useEffect(() => {
    setLoading(loadingGroups && loadingRegions);
  }, [loadingGroups, loadingRegions]);

  useEffect(() => {
    if (regionData) {
      const convertedLocationsData = convertLocationsToGeoJSON(regionData);
      setGeoJSONData(convertedLocationsData);
    }
  }, [regionData]);

  const convertLocationsToGeoJSON = (databaseLocations: any) => {
    return {
      type: "FeatureCollection",
      features: databaseLocations.map((location: any) => {
        // Parse the geometry and adjust the coordinates
        const geometry = JSON.parse(location?.geojson?.geometry);

        // Handle MultiPolygon and Polygon
        const adjustedCoordinates = geometry.coordinates.map((polygon: any) => {
          return polygon[0].map(([lat, lon]: number[]) => [lat, lon]);
        });

        return {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: adjustedCoordinates,
          },
          properties: location?.geojson?.properties,
          url: location.url,
        };
      }),
    };
  };

  const editCamera = (data: FieldValues, savedLocation?: any) => {
    const selectedGroup = data.selectedGroup;
    let payload: PostCameraItem;

    if (savedLocation) {
      // If savedLocation is provided,update both location and group
      payload = {
        id: formData?.id,
        location: savedLocation.id,
        group: selectedGroup.value,
        camera_id: data.camera_id,
        has_gps: data.has_gps,
      };
    } else {
      //   If savedLocation is not provided, update only group
      payload = {
        id: formData?.id,
        group: selectedGroup.value,
        camera_id: data.camera_id,
        has_gps: data.has_gps,
      };
    }

    const toastId = toast.loading("Please wait...");
    const updateCamera$ = updateCamera(payload);
    const sub = updateCamera$.subscribe({
      next: (res: any) => {
        toast.update(toastId, {
          render: "Camera has been updated successfully.",
          type: "success",
          isLoading: false,
          autoClose: 3000,
          closeButton: true,
          hideProgressBar: false,
          theme: "colored",
        });
        onClose();
      },
      error: (error) => {
        toast.update(toastId, {
          render: "Error! Something went wrong",
          type: "error",
          isLoading: false,
          autoClose: 3000,
          closeButton: true,
          hideProgressBar: false,
        });
        if (error.status == 400) {
          setAPIErrors(error.response);
        }
      },
    });
    return () => sub.unsubscribe();
  };

  const handleLocationAndCamera = (
    data: FieldValues,
    selectedLocation: { longitude: any; latitude: any }
  ) => {
    const payload: PostLocationItem = {
      point: `SRID=4326;POINT (${selectedLocation?.longitude} ${selectedLocation?.latitude})`,
      name: data?.location_name,
    };

    const postLocation$ = postLocation(payload);
    const sub = postLocation$.subscribe({
      next: (res) => {
        const savedLocation = res;
        formData
          ? editCamera(data, savedLocation)
          : createCamera(data, savedLocation);
      },
      error: (error) => {
        if (error.status == 400) {
          setAPIErrors(error.response);
        }
      },
    });
    return () => sub.unsubscribe();
  };

  const createCamera = (data: FieldValues, savedLocation?: any) => {
    let payload: PostCameraItem;
    if (savedLocation) {
      // If savedLocation is provided,update both location and group
      payload = {
        location: savedLocation.id,
        group: data.selectedGroup.value,
        camera_id: data.camera_id,
        has_gps: data.has_gps,
      };
    } else {
      // If savedLocation is not provided, update only group
      payload = {
        group: data.selectedGroup.value,
        camera_id: data.camera_id,
        has_gps: data.has_gps,
      };
    }
    const toastId = toast.loading("Please wait...");
    const postCamera$ = postCamera(payload);
    const sub = postCamera$.subscribe({
      next: (res: any) => {
        toast.update(toastId, {
          render: "Camera has been created successfully.",
          type: "success",
          isLoading: false,
          autoClose: 3000,
          closeButton: true,
          hideProgressBar: false,
          theme: "colored",
        });
        onClose();
      },
      error: (error) => {
        toast.update(toastId, {
          render: "Error! Something went wrong",
          type: "error",
          isLoading: false,
          autoClose: 3000,
          closeButton: true,
          hideProgressBar: false,
        });
        if (error.status == 400) {
          setAPIErrors(error.response);
        }
      },
    });
    return () => sub.unsubscribe();
  };

  const onSubmit = async () => {
    const data = getValues();
    // Only trigger validation for latitude and longitude when the accordion is collapsed
    const isValid = isAccordionCollapsed
      ? await trigger(["camera_id", "latitude", "longitude"])
      : await trigger(["camera_id"]);

    if (isValid) {
      const selectedLocation = {
        latitude: data.latitude,
        longitude: data.longitude,
      };

      // If no formData exists, create a new camera
      if (!formData && !isAccordionCollapsed) {
        createCamera(data);
      }
      // If has_gps is false, and valid location data is present, handle both location creation and camera creation
      else if (
        !hasGpsValue &&
        selectedLocation.longitude !== undefined &&
        selectedLocation.latitude !== undefined &&
        isAccordionCollapsed
      ) {
        handleLocationAndCamera(data, selectedLocation);
      }
      // If formData exists and has_gps is true or accordion is not collapsed, update the camera
      else if ((formData?.id && hasGpsValue) || !isAccordionCollapsed) {
        editCamera(data);
      }
    }
  };

  const handleLocationSelection = (selectedLocation: LatLng) => {
    clearErrors(["latitude", "longitude"]);
    setValue("latitude", selectedLocation.lat);
    setValue("longitude", selectedLocation.lng);
  };

  const handleAccordionSelect = (
    eventKey: AccordionEventKey,
    e: SyntheticEvent<unknown, Event>
  ): void => {
    const isAccordionCollapsed = eventKey === "0";
    setIsAccordionCollapsed(isAccordionCollapsed);
  };
  if (loading) {
    return <div>Loading ...</div>;
  }
  return (
    <Container>
      {APIErrors && <ErrorAlertFor400 APIErrorMessages={APIErrors} />}
      <div className="col-auto">
        <div className="row mb-3 mt-3">
          <div className="col-md-5 d-flex align-items-center">
            <h4 className="mb-0">
              {isAdminUser && !formData
                ? "Add Camera"
                : isAdminUser
                ? `Update ${formData?.camera_id}`
                : `Update ${formData?.camera_id} location`}
            </h4>
          </div>
          <div className="col-md-7 float-end text-end">
            <button
              type="button"
              onClick={onClose}
              className="btn btn-secondary mx-2"
            >
              Cancel
            </button>
            {formData && (
              <button
                type="button"
                onClick={() => onSubmit()}
                className="btn btn-primary mx-2"
              >
                {"Update"}
              </button>
            )}
            {!formData && (
              <button
                type="button"
                onClick={() => onSubmit()}
                className="btn btn-primary mx-2"
              >
                Save & Close
              </button>
            )}
          </div>
        </div>
        <hr />
        {isAdminUser && (
          <div
            className="col-md-12 mb-4 mt-4"
            style={{
              zIndex: 100,
              display: "block",
              position: "relative",
            }}
          >
            {formData && (
              <LabelAndInput
                label="Camera Id"
                register={register}
                registerName="camera_id"
                labelClassName={"required"}
                rules={{ required: "Please enter the Camera Id" }}
                fieldError={errors?.camera_id?.message ?? undefined}
                handleFocus={() => clearErrors("camera_id")}
                readOnly={false}
              />
            )}
            {!formData && (
              <LabelAndInput
                label="Camera Id"
                register={register}
                registerName="camera_id"
                labelClassName={"required"}
                rules={{ required: "Please enter the Camera Id" }}
                fieldError={errors?.camera_id?.message ?? undefined}
                handleFocus={() => clearErrors("camera_id")}
                readOnly={false}
              />
            )}
            {!groups?.length && !formData?.groups?.length && (
              <div>No groups available for selection.</div>
            )}
            {(groups?.length > 0 || formData?.groups?.length > 0) && (
              <SelectInput
                isMulti={false}
                id={"group-select"}
                name="selectedGroup"
                control={control}
                options={groups}
                placeholder="Select a group"
                label="Community Group"
              />
            )}
            <div key={`default-checkbox`} className="mt-3">
              <Controller
                name="has_gps"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <Form.Check
                    type={"checkbox"}
                    id={`default-checkbox`}
                    label={`Camera supports GPS`}
                    checked={field.value}
                    onChange={(e) => setValue("has_gps", e.target.checked)}
                  />
                )}
              />
            </div>
          </div>
        )}
        {!hasGpsValue && (
          <Accordion onSelect={handleAccordionSelect}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Click here to set camera location
              </Accordion.Header>
              <Accordion.Body>
                <Row
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="align-items-center justify-content-center mt-3"
                  style={{
                    zIndex: 1,
                    display: "block",
                    position: "relative",
                  }}
                >
                  {geoJSONData && (
                    <Row
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      className="align-items-center mt-3"
                    >
                      <Form.Label>Set camera location</Form.Label>
                      <small>
                        Select your desired location either by clicking on the
                        map, manually entering latitude and longitude, or using
                        the 'Use My Location' button (requires enabling location
                        services).
                      </small>
                      {(errors?.latitude?.message ||
                        errors?.longitude?.message) && (
                        <span
                          className="text-danger"
                          data-testid="error-message"
                        >
                          To set the camera location, please provide latitude
                          and longitude values. If not, you can close the
                          accordion.
                        </span>
                      )}
                      {userLocationContext.error && (
                        <div className="mt-3 alert alert-danger" role="alert">
                          {userLocationContext.error}
                        </div>
                      )}
                      <Col xs={12} sm={12} md={6} lg={4} className="mb-3 mt-3">
                        <MapComponent
                          locationsData={geoJSONData}
                          onLocationSelect={handleLocationSelection}
                          formData={formData}
                        />
                      </Col>
                      <Col xs={12} sm={12} md={6} lg={8} className="mb-3">
                        <Row className="align-items-center mt-3">
                          <Col xs={12} sm={12} md={7} lg={4} className="mb-3">
                            <LabelAndInput
                              label="Location name"
                              register={register}
                              registerName="location_name"
                              readOnly={false}
                            />
                            <LabelAndInput
                              label="Latitude"
                              register={register}
                              registerName="latitude"
                              labelClassName={"required"}
                              rules={{ required: "Please enter the Latitude" }}
                              fieldError={
                                errors?.latitude?.message ?? undefined
                              }
                              handleFocus={() => clearErrors("latitude")}
                              readOnly={false}
                            />
                            <LabelAndInput
                              label="Longitude"
                              register={register}
                              registerName="longitude"
                              labelClassName={"required"}
                              rules={{ required: "Please enter the Longitude" }}
                              fieldError={
                                errors?.longitude?.message ?? undefined
                              }
                              handleFocus={() => clearErrors("longitude")}
                              readOnly={false}
                            />
                          </Col>

                          <Col
                            xs={12}
                            sm={12}
                            md={1}
                            lg={1}
                            className="d-flex justify-content-center mb-3"
                          >
                            OR
                          </Col>

                          <Col
                            xs={12}
                            sm={12}
                            md={4}
                            lg={3}
                            className="d-flex justify-content-start mb-3"
                          >
                            <Button
                              variant="secondary"
                              className=""
                              onClick={() => onUseMyLocationClick()}
                            >
                              Use My Location
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
      </div>
    </Container>
  );
};

export default AddEditCamera;
