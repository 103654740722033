import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Container } from 'react-bootstrap';
import griffithLogo from "../assets/images/griffithLogo.jpg";
import UserMenu from './UserMenu';

const TopNavbar = () => {
  return (
    <Navbar collapseOnSelect expand="lg"  >
      <Container>
        <Navbar.Brand href="/">
          <img
            src={griffithLogo}
            height="auto"
            className="d-inline-block align-top"
            alt="AI for Koala"
          />
        </Navbar.Brand>
        <Navbar.Brand href="/">AI for Koala</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav justify-content-end">
          <Nav className="me-auto">
          </Nav>
          <UserMenu />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default TopNavbar;