import React, { createContext, useContext, useState } from "react";

interface NotificationContextProps {
  showAlert: boolean;
  setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NotificationItemsContext = createContext<NotificationContextProps>(
  {
    showAlert: false,
    setShowAlert: () => {},
  }
);

export const useNotificationItemsContext = () =>
  useContext(NotificationItemsContext);

const NotificationItemsProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [showAlert, setShowAlert] = useState<boolean>(false);

  return (
    <NotificationItemsContext.Provider
      value={{
        showAlert,
        setShowAlert,
      }}
    >
      {children}
    </NotificationItemsContext.Provider>
  );
};

export default NotificationItemsProvider;
