import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Notifications from "./Notifications";
import { getNotifications } from "../services/Notification";
import { NotificationItem } from "../models/Notification";

function OffCanvas({ title, ...props }: any) {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const [pastNotificationItems, setPastNotificationItems] = useState<
    NotificationItem[]
  >([]);

  const loadNotifications = () => {
    const getNotifications$ = getNotifications({
      is_read: true,
    });
    const sub = getNotifications$.subscribe({
      next: (res: any) => {
        setPastNotificationItems(res.results);
      },
    });
    return () => sub.unsubscribe();
  };

  useEffect(() => {
    loadNotifications();
  }, []);

  return (
    <>
      <Button
        className="me-2"
        onClick={handleShow}
        variant="outline-primary"
        disabled={
          title === "Past Notifications" && pastNotificationItems.length == 0
            ? true
            : false
        }
      >
        {title}
      </Button>

      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Notifications key={title} title={title} />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default OffCanvas;
