import { Observable } from "rxjs";
import { baseURL } from "./ServiceUtil";
import { PostUserItem, User } from "../models/User";
import { customAjax } from "../components/ErrorHandler";

export const getUsers = (page?: number | string): Observable<User> => {
  let queryParams = '';
  
  if (page !== undefined && page !== null) {
    if (page === 'all') {
      queryParams = new URLSearchParams({ page_size: 'all' }).toString();
    } else {
      queryParams = new URLSearchParams({ page: page.toString() }).toString();
    }
  }

  const url = `${baseURL}/users${queryParams ? '?' + queryParams : ''}`;

  return customAjax(url);
};

export const postUser = (userPayload: PostUserItem) =>
  customAjax(`${baseURL}/users`, 'POST', userPayload);


export const deleteUser = (userId: number) =>
  customAjax(`${baseURL}/users/${userId}`, 'DELETE');