import { Col, ListGroup, Row } from "react-bootstrap";
import { GroupItem } from "../models/Group";
import CustomModal from "./CustomModal";
import { useEffect, useState } from "react";
import AddEditGroup from "./AddEditGroup";
import { getGroups } from "../services/Groups";
import Paginator from "./Paginator";
import Spinner from "./Spinner";
import HideUnhideGroup from "./HideUnhideGroup";

const GroupEntries = () => {
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showVisibilityModal, setShowVisibilityModal] =
    useState<boolean>(false);
  const [groups, setGroups] = useState<GroupItem[]>([]);
  const [formData, setFormData] = useState<any>(null);
  // const [propFormData, setPropFormData] = useState<any>(null);
  const [page, setPage] = useState<any>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);

  const loadGroups = () => {
    setLoading(true);
    getGroups({ userGroups: false }).then((items: any) => {
      console.log("groups from line 25 groupEnteries", items);
      items.map((item: any) => {
        if (item.groupprop == null) {
          item.groupprop = { is_visible: true, group: item.id };
        }
      });
      setPage(items);
      setGroups(items);
      setLoading(false);
    });
  };

  useEffect(() => {
    loadGroups();
  }, [pageNumber]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <CustomModal
            show={showAddModal}
            scrollable={false}
            modalBody={
              <AddEditGroup
                formData={formData}
                onClose={() => {
                  setShowAddModal(false);
                  setFormData(null);
                  loadGroups();
                }}
              />
            }
          />
          <CustomModal
            show={showVisibilityModal}
            scrollable={false}
            modalBody={
              <HideUnhideGroup
                formData={formData}
                onClose={() => {
                  setShowVisibilityModal(false);
                  setFormData(null);
                  loadGroups();
                }}
              />
            }
          />
          <Row>
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              className="d-flex justify-content-end"
            >
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setShowAddModal(true)}
              >
                + Add Group
              </button>
            </Col>
          </Row>

          <Row className="mt-2">
            <ListGroup>
              {groups.map((group) => (
                <ListGroup.Item
                  className="d-flex justify-content-between align-items-start"
                  key={group.id}
                >
                  <div className="ms-2 me-auto p-0 list-item-wrapper">
                    <div
                      className={
                        group.groupprop.is_visible ? "fw-bold" : "text-muted"
                      }
                    >
                      {group.name}
                    </div>
                  </div>
                  <div className="list-item-wrapper ">
                    <button
                      type="button"
                      className={
                        group.groupprop.is_visible
                          ? "btn btn-light"
                          : "btn btn-light disabled"
                      }
                      onClick={() => {
                        setFormData(group);
                        setShowAddModal(true);
                      }}
                    >
                      <i
                        className="bi bi-pencil-square"
                        id={`edit-group-entry-${group.id}`}
                      ></i>
                    </button>
                    <button
                      type="button"
                      className="btn list-action-button"
                      onClick={() => {
                        setFormData(group);
                        setShowVisibilityModal(true);
                      }}
                    >
                      <i
                        className={
                          group.groupprop.is_visible
                            ? "bi bi-eye"
                            : "bi bi-eye-slash"
                        }
                        id={`hide-group-entry-${group.id}`}
                      ></i>
                    </button>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Row>
          <Paginator
            page={page}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </>
      )}
    </>
  );
};

export default GroupEntries;
