import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import griffithLogo from "../assets/images/GRIFF1_STD_RGB_reverse.png";


const Footer = () => (

    <footer id="Footer" className="text-light py-2 m-0 footer">
        <Container>
            <Row>
                <Col xs={6}>
                    <a
                        href="https://www.griffith.edu.au"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img alt="Griffith Logo" src={griffithLogo} height={38} />
                    </a>
                </Col>
                <Col xs={6} className="d-flex justify-content-end align-self-center" style={{ fontWeight: "300" }}>
                    CRICOS Provider - 00233E | TEQSA - PRV12076
                </Col>
            </Row>
        </Container>
    </footer>
);

export default Footer;
