import React from 'react';
import { Row, ButtonGroup, Button } from 'react-bootstrap';

interface PaginatorProps {
  page: {
    results: any[]; // Adjust the type as per your actual data
    count: number;
    previous: string | null;
    next: string | null;
  } | null;
  pageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
}

const Paginator: React.FC<PaginatorProps> = ({ page, pageNumber, setPageNumber }) => {
  return (
    <>
      {page && page?.results?.length > 0 && (
        <Row className="mt-2">
          <div
            className="d-flex justify-content-between"
            style={{ boxShadow: "0 0 0" }}
          >
            <div>
              Showing Entry {(pageNumber - 1) * 10 + 1} to{" "}
              {Math.min(pageNumber * 10, page?.count)} of {page?.count}
            </div>
            <div>
              <ButtonGroup aria-label="Basic example">
                <Button
                  size="sm"
                  variant="secondary"
                  disabled={page?.previous === null}
                  onClick={() => setPageNumber(pageNumber - 1)}
                >
                  Previous
                </Button>
                <Button
                  size="sm"
                  variant="secondary"
                  disabled={page?.next === null}
                  onClick={() => setPageNumber(pageNumber + 1)}
                >
                  Next
                </Button>
              </ButtonGroup>
            </div>
          </div>
        </Row>
      )}
    </>
  );
};

export default Paginator;
