import { useContext } from "react";
import { Dropdown, NavDropdown, NavLink, Nav } from "react-bootstrap";
import { useAuth } from "react-oidc-context";
import { LinkContainer } from "react-router-bootstrap";
import { SignInContext } from "../context/SignInContext";

const UserMenu: React.FC = () => {
  const auth = useAuth();
  const { isAdminUser, userGroupsCount } = useContext(SignInContext);

  if (!auth.isAuthenticated) {
    return (
      <Nav.Link
        className={"text-black nav-1-menu mt-auto"}
        onClick={() => {
          auth.signinRedirect();
        }}
      >
        Sign In
      </Nav.Link>
    );
  }

  const signOutRedirect = () =>
    auth
      .revokeTokens(["access_token", "refresh_token"])
      .then(() => {
        console.log("tokens revoked");

        return auth.removeUser();
      })
      .catch((_err) => Promise.reject("Logout Error: err"));

  return (
    <Nav>
      <LinkContainer to="/dashboard">
        <Nav.Link>Dashboard</Nav.Link>
      </LinkContainer>
      {/* {isAdminUser && (
        <LinkContainer to="/reports">
          <Nav.Link>Reports</Nav.Link>
        </LinkContainer>
      )} */}
      <Dropdown className="mt-auto">
        <Dropdown.Toggle id="user-dropdown" as={NavLink}>
          {auth.user?.profile.given_name ||
            auth.user?.profile.name ||
            auth.user?.profile.preferred_username ||
            auth.user?.profile.email}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {userGroupsCount != null && userGroupsCount > 0 && (
            <>
              <LinkContainer to="/manage-cameras">
                <NavDropdown.Item>Manage Cameras</NavDropdown.Item>
              </LinkContainer>

              {isAdminUser && (
                <LinkContainer to="/manage-groups">
                  <NavDropdown.Item>Manage Groups</NavDropdown.Item>
                </LinkContainer>
              )}
              <LinkContainer to="/past-notifications">
                <NavDropdown.Item>View Past Notifications</NavDropdown.Item>
              </LinkContainer>
              <NavDropdown.Divider />
            </>
          )}
          <Dropdown.Item onClick={() => signOutRedirect()}>
            {" "}
            Sign out
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Nav>
  );
};

export default UserMenu;
